<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
      :key="key"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('orders.edit') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col md="12">
          <text-input
              v-if="isMedicine"
              v-model="form.medicine_name"
              :label="$t('products.table.medicine_name')"
              :placeholder="$t('form.text.placeholder')"
              rules="required"
          />
        </b-col>
        <b-col md="6">
          <text-input
              v-if="isMedicine"
              v-model="form.medicine_quantity"
              :label="$t('table.quantity')"
              :placeholder="$t('form.text.placeholder')"
              rules="required"
              type="number"
          />
        </b-col>
        <b-col md="6">
          <wameed-dropdown
              v-model="vehicle"
              :label="$t('table.vehicle_type')"
              :placeholder="$t('form.text.placeholder')"
              variant="disable"
              rules="required"
              :items="getVehicleTypes"
              class="wameed_dropdown"

              @input="onVehicleChange"
              :no_options="$t('common.no_options')"
          />
        </b-col>
        <b-col md="6">
          <wameed-dropdown
              v-if="editOrderType"
              v-model="orderType"
              :label="$t('table.service_type')"
              :placeholder="$t('form.text.placeholder')"
              variant="disable"
              rules="required"
              :items="getOrderTypes"
              class="wameed_dropdown"
              @input="onTypeChange"
              :no_options="$t('common.no_options')"
          />
        </b-col>
        <b-col md="12">
          <text-input
              v-model="form.note"
              :label="$t('common.note')"
              :placeholder="$t('form.text.placeholder')"
              rules="required"
          />
        </b-col>
      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.ok')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submitOrder()"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapGetters} from 'vuex';


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    editOrderType: {
      type: Boolean,
      default: false,
    },
    isMedicine: {
      type: Boolean,
      default: false,
    },
    modalData: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      key: 0,
      orderType: null,
      vehicle: null,
      form: {
        note: '',
        vehicle_type: '',
        order_type: '',
      },
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getVehicleTypes: 'vendor/orders/getVehicleTypes',
      getOrderTypes: 'vendor/orders/getOrderTypes',
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
        if (newVal.order[0].medicine_name) {
          this.form.medicine_name = newVal.order[0].medicine_name;
          this.form.medicine_quantity = newVal.order[0].quantity;
        }
        if (newVal.order[0].order_type) {
          this.orderType = this.getOrderTypes.find((item) => item.name == newVal.order[0].order_type)
        }
        this.vehicle = this.getVehicleTypes.find((item) => item.name == newVal.order[0].vehicle_type)

      } else {
        this.form.note = '';
      }
    },
  },
  created() {
    if (this.modalData != null) {
      this.form = this.modalData
    }
  },
  methods: {

    onVehicleChange() {
      this.key++;
    },
    onTypeChange() {
      this.key++;
    },
    submitOrder() {

      this.form.vehicle_type = this.vehicle.id;
      if (this.orderType)
        this.form.order_type = this.orderType.id;

      this.$store.dispatch('vendor/orders/updateOrder', {
        ...this.form,
        id: this.$route.params.id,

      }).then(() => {
        this.$emit('onComplete', true);
        this.closeModal();
        this.$store.commit('loadingFinish', null, {root: true});
      });

    },


    closeModal() {
      this.form = {
        note: '',
        vehicle_type: '',
        order_type: '',
      };
      this.show = false;
    },
  },
};
</script>
