<template>
  <b-modal
      v-model="show"
      content-class="package-modal rounded-8 assign-driver-modal"
      dialog-class="wameed-modal-md"
      no-fade
  >
    <template slot="modal-header-close">
      <close-icon/>
    </template>
    <template slot="modal-title">
      <div class="d-block text-center">
        <h3 class="text-reg-18">
          {{ $t('orders.modal.driver.assign') }}
        </h3>
      </div>
    </template>


    <div class="body">

      <div class="search-header">
        <div class="search-filters">

          <div class="filter"
               :class="form.filter===2?'active':''"
               @click="()=>filterClick(2)">
            <span>{{ $t('common.all') }}</span>
          </div>

          <div class="filter"
               :class="form.filter===1?'active':''"
               @click="()=>filterClick(1)">
            <span>{{ $t('status.available') }}</span>
          </div>

          <div class="filter"
               :class="form.filter===0?'active':''"
               @click="()=>filterClick(0)">
            <span>{{ $t('status.not_available') }}</span>
          </div>

        </div>
        <div class="">
          <text-input
              id="login-email"
              v-model="form.search"
              icon="search-icon"
              is-append
              input-classes="text-reg-14 "
              name="search"
              :placeholder="$t('common.search') + ' ...'"
              field-classes="mb-0 "
          />
        </div>
      </div>

      <div class="driver-list">
        <driver-card v-for="driver in filterDrivers"
                     :key="driver.id"
                     :data="driver"
                     @onClick="assignDriver(driver.id)"
        />
      </div>
    </div>


    <template slot="modal-footer" class="d-none">

    </template>
  </b-modal>
</template>

<script>
import {WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import DriverCard from "@/views/pages/orders/components/driverCard";
import {mapGetters} from "vuex";


export default {
  components: {
    DriverCard,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    orderId: [Number, String],
    category: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      form: {
        search: '',
        filter: 2
      },
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      drivers: 'vendor/orders/getDrivers',
    }),
    filterDrivers() {

      return this.drivers.filter((driver) => {
        return (driver.status == this.form.filter || 2 == this.form.filter) && (driver.name.indexOf(this.form.search) > -1 || this.form.search == '')
      })
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.form = {
            search: '',
            filter: 2
          }
          this.$emit('close');
        }
      },
    },
  },
  methods: {

    async filterClick(filter) {
      await this.loadData();
      this.form.filter = filter;
    },


    assignDriver(id) {
      this.$store.dispatch('vendor/orders/assignDriver', {
        'driver_id': id,
        'order_id': this.orderId,
      }).then(() => {
        this.closeModal();
      })
    },


    closeModal() {
      this.show = false;
      this.form = {
        search: '',
        filter: 2
      }
    },
    loadData() {
      this.$store.dispatch('vendor/orders/drivers')
    },
  },
  mounted() {
    this.loadData();
  }
};
</script>
