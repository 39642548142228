var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('w-tables',{attrs:{"noDataTitle":_vm.$t('table.no_data_title'),"noDataSubtitle":_vm.$t('table.no_data_subtitle'),"items":_vm.order,"fields":_vm.computedFields,"perPage":10000,"custimized-items":[
              { name: 'name' },
              { name: 'price' },
              { name: 'total' },
              { name: 'size' },
              { name: 'type' },
              { name: 'addons' },
              { name: 'dimension' },
              { name: 'quantity' } ]},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"order-table_name",class:_vm.editOrder?'cursor-pointer':'',on:{"click":function($event){return _vm.$emit('onCartEdit',data.item)}}},[_c('name-tile',{attrs:{"name":data.item.name,"image":data.item.image}}),(_vm.editOrder)?_c('div',{staticClass:"edit"},[_vm._v(_vm._s(_vm.$t('common.edit')))]):_vm._e()],1)]}},{key:"size",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.size}})]}},{key:"dimension",fn:function(ref){
            var data = ref.data;
return [_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.length'))+" : "),_c('span',{staticClass:"text-main"},[_vm._v(_vm._s(data.item.dimension.length)+" ")]),_vm._v(_vm._s(_vm.$t('common.meter'))+" , ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.width'))+" : "),_c('span',{staticClass:"text-main"},[_vm._v(_vm._s(data.item.dimension.width)+" ")]),_vm._v(_vm._s(_vm.$t('common.meter'))+" , ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.height'))+" : "),_c('span',{staticClass:"text-main"},[_vm._v(_vm._s(data.item.dimension.height)+" ")]),_vm._v(_vm._s(_vm.$t('common.meter'))+" , ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('common.weight'))+" : "),_c('span',{staticClass:"text-main"},[_vm._v(_vm._s(data.item.dimension.weight)+" ")]),_vm._v(_vm._s(_vm.$t('common.kg')))])])]}},{key:"type",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.type}})]}},{key:"addons",fn:function(ref){
            var data = ref.data;
return [_c('check-value',{attrs:{"value":data.item.addons}})]}},{key:"price",fn:function(ref){
            var data = ref.data;
return [_c('check-price',{attrs:{"price":data.item.old_price,"dis-price":data.item.price}})]}},{key:"total",fn:function(ref){
            var data = ref.data;
return [_c('check-price',{attrs:{"price":data.item.total}})]}},{key:"quantity",fn:function(ref){
            var data = ref.data;
return [_vm._v(" x"+_vm._s(data.item.quantity)+" ")]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }