<template>
  <div>

    <div class="tile-text between bill-tile">
      <div class="text" :class="textColor">
        {{ title }}

        <span v-if="('code' in data) && data.code" class="text-success">({{ data.code }})</span>
      </div>
      <div class="value">
        <check-price
            :price="data.price"
            :dis-price="data.price-data.dis_price"
            :change-dir="true"
            :can-be-free="data.dis_price!=null"
            :class="priceColor"
        />
      </div>

    </div>
    <div v-if="type=='coupon'">
      <div class="d-flex justify-content-between bill-tile">
        <div>{{ $t('orders.delivery_discount') }}</div>
        <check-price
            :price="data.delivery_discount"
            :change-dir="true"
            :class="priceColor"
        />
      </div>

      <div class="d-flex justify-content-between bill-tile">
        <div>{{ $t('orders.order_discount') }}</div>
        <check-price
            :price="data.order_discount"
            :change-dir="true"
            :class="priceColor"
        />
      </div>

    </div>
  </div>
</template>

<script>


import CheckPrice from "@/components/checkPrice";


export default {
  components: {CheckPrice},
  props: {
    data: Object,
    title: String,
    type: String
  },
  computed: {
    priceColor() {
      if (this.type === 'total') {
        return 'text-main';
      } else if (this.type === 'coupon') {
        return 'text-success';
      } else if (this.type === 'service') {
        return 'text-danger';
      }
      return '';
    },
    textColor() {
      if (this.type === 'service') {
        return 'text-danger';
      }
      return '';
    }
  }
};
</script>
