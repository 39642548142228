<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('status.change') }}
          </h3>
        </div>
      </template>


      <div class="text-danger mb-5">
        {{ $t('status.warning_message') }}
      </div>

      <div class="d-flex flex-wrap" style="gap: 12px">
        <div class="status-container clickable"
             @click="changeToActive(status)"
             :class="status.id==activeStatus? 'custom':''"
             v-for="status in getStatues" :style="`--color:#${status.color};--bgColor:${bgColor(status.color)}`">
          <div class="status-container--text text">
            {{ status.name }}
          </div>
        </div>
      </div>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.update')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="showConfirmModal=true"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>


      <warning-modal
          variant="danger"
          iconName='o-warning-danger-icon'
          :visible="showConfirmModal"
          @close="showConfirmModal = false"

          @submitAction="changeStatus"
          :title="$t('status.modal.title')"
          :subTitle="$t('status.modal.desc')"
          :btnTitle="$t('common.change')"
      />
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {WameedBtn,} from 'wameed-ui/dist/wameed-ui.esm';
import {mapActions, mapGetters} from 'vuex';
import WarningModal from "@/components/WarningModal.vue";


export default {
  components: {
    WarningModal,
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    orderId: {
      type: [Number, String],
      default: null,
    },

  },

  data() {
    return {
      showConfirmModal: false,
      activeStatus: 0,
      form: {
        note: '',
        reason: null,
      },
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      getStatues: 'vendor/orders/getStatues',
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.activeStatus = 0;
          this.$emit('close');
        }
      },
    },

  },


  methods: {
    ...mapActions({
      cancel: "vendor/orders/cancel",
      update: "vendor/categories/update",
    }),
    changeToActive(status) {
      this.activeStatus = status.id;
    },
    bgColor(color) {
      let rgb = this.hexToRgb('#' + color);
      return `rgba(${rgb.r},${rgb.g},${rgb.b},.1)`
    },
    hexToRgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },
    changeStatus() {
      if (this.activeStatus !== 0) {
        this.$store.dispatch('vendor/orders/changeOrderStatus',
            {id: this.orderId, status: this.activeStatus})
            .then(() => {
              this.$emit('onComplete', true);
              this.closeModal();
              this.$store.commit('loadingFinish', null, {root: true});
            });
      }
    },
    closeModal() {
      this.show = false;
      this.activeStatus = 0;
      this.showConfirmModal = 0;
      this.form.note = '';
    },
  },
};
</script>
