<template>
  <b-modal
      v-model="show"
      content-class="package-modal rounded-8 product-detail-modal"
      dialog-class="wameed-modal-md"
      no-fade
  >
    <template slot="modal-header-close">
      <close-icon/>
    </template>
    <template slot="modal-title">
      <div class="d-block text-center">
        <h3 class="text-reg-18">
          {{ $t('orders.products.title') }}
        </h3>
      </div>
    </template>


    <div class="body">


      <div class="product-info">
        <div class="product-info_data">

          <!--          <img src="https://backend.talqa.wameedprojects.com/uploads/amirali-mirhashemian-sc5sTPMrVfk-unsplash.jpg"-->
          <!--               alt="">-->
          <img v-if="detail.image && showImage" :src=detail.image :alt=detail.name @error="onError" class="product-img">
          <div v-else class="product-img">
                <span>
        {{ trimName[0] }}
               </span>
          </div>
        </div>

        <div class="product-info_data_col">
          <div class="name">{{ detail.name }}</div>
          <div class="desc">{{ detail.description }}</div>
          <div class="option">
            <check-price
                :price="prodPrice"
                :dis-price="detail.dis_price"
            />
            <div class="counter">
              <div class="counter-btn" @click="plusAction">
                <o-plus-icon/>
              </div>
              <div class="number">
                {{ form.counter }}
              </div>
              <div class="counter-btn" :class="minusDisabled?'disable':''" @click="minusAction">
                <o-minus-icon/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divider"></div>

      <div>
        <div class="options-info">
          <div class="flex">
            <div class="name"> {{ $t('orders.products.modal.sizes') }}</div>
            <div class="type badge badge-light-danger badge-pill px-3">{{ $t('orders.products.modal.required') }}</div>
          </div>
          <div class="desc">{{ $t('orders.products.modal.choose-one') }}</div>
        </div>

        <div class="product-options">
          <label class="custom-input input-bottom-border" v-for="size in detail.sizes" :key="size.id">
            <input :value="JSON.stringify(size)"
                   :checked="checkSizeInCart(size)"
                   type="radio"
                   name="size"
                   @change="chooseProductSize"
            />
            <span class="text-reg-16">{{ size.name }}</span>
            <span class="ml-auto text-reg-16">
                <span v-if="size.price==0">
                {{ $t('common.free') }}
              </span>
              <span v-else>
                {{ formatNumber(size.price) }}
              </span>
            </span>
          </label>
        </div>

      </div>

      <div class="divider" v-if="detail.types && detail.types.length>0"/>
      <div v-if="detail.types && detail.types.length>0">
        <div class="options-info">
          <div class="flex">
            <div class="name">{{ $t('orders.products.modal.types') }}</div>
            <div class="type badge badge-light-font-sub badge-pill px-3">{{
                $t('orders.products.modal.optional')
              }}
            </div>
          </div>
          <div class="desc">{{ $t('orders.products.modal.choose-one') }}</div>
        </div>

        <div class="product-options">
          <label class="custom-input input-bottom-border" v-for="type in detail.types" :key="type.id">
            <input :value="JSON.stringify(type)"
                   :checked="checkTypeInCart(type)"
                   type="radio"
                   name="type"
                   @change="chooseProductType"
            />
            <span class="text-reg-16">{{ type.name }}</span>
            <span class="ml-auto text-reg-16">
                <span v-if="type.price==0">
                {{ $t('common.free') }}
              </span>
              <span v-else>
                +{{ formatNumber(type.price) }}
              </span>
            </span>
          </label>
        </div>

      </div>

      <div class="divider" v-if="detail.addons && detail.addons.length>0"/>
      <div v-if="detail.addons && detail.addons.length>0">
        <div class="options-info">
          <div class="flex">
            <div class="name">{{ $t('orders.products.modal.addons') }}</div>
            <div class="type badge badge-light-font-sub badge-pill px-3">{{
                $t('orders.products.modal.optional')
              }}
            </div>
          </div>
          <div class="desc">{{ $t('orders.products.modal.choose-multi') }}</div>
        </div>
        <div class="product-options">
          <label class="custom-input input-bottom-border" v-for="addon in detail.addons" :key="addon.id">
            <input :value="JSON.stringify(addon)"
                   :checked="checkAddonInCart(addon)"
                   type="checkbox"
                   name="addon"
                   @change="chooseProductAddons"
            />
            <span class="text-reg-16">{{ addon.name }}</span>
            <span class="ml-auto text-reg-16 ">
              <span v-if="addon.price==0">
                {{ $t('common.free') }}
              </span>
              <span v-else>
                +{{ formatNumber(addon.price) }}
              </span>
            </span>
          </label>
        </div>

      </div>
    </div>


    <template slot="modal-footer" class="d-none">
      <wameed-btn
          variant="main"
          size="md"
          :title="cartProduct?$t('common.save_edit'):$t('common.add_to_cart')"
          classes="text-book-18  rounded-12 w-unset px-4 m-0 main-btn"
          block
          @onClick="fillData"
      />
      <wameed-btn
          variant="gray"
          size="md"
          :title="$t('common.cancel')"
          classes="text-book-18  rounded-12 w-unset px-4 m-0 gray-btn"
          :class="cartProduct?'move-right':''"
          block
          @onClick="closeModal"
      />
      <wameed-btn
          v-if="cartProduct"
          variant="light-danger"
          size="md"
          :title="$t('common.delete_form_cart')"
          classes="text-book-18  rounded-12 w-unset px-4 m-0 border-0 light-danger-btn"
          block
          @onClick="removeFromCart"
      />
    </template>
  </b-modal>
</template>

<script>
import {WameedBtn, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapGetters} from "vuex";
import CheckPrice from "@/components/checkPrice";


export default {
  components: {
    CheckPrice,
    WameedBtn,
    TextInput,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    orderId: [Number, String],
    cartProduct: {
      type: Object,
      default: null,
    },

  },

  data() {
    return {
      form: {
        counter: 1,
        price: 0,
        size: null,
        type: null,
        addons: [],
        sizePrice: 0,
        typePrice: 0,
        addonsPrice: 0,
      },
      error: {},

      showImage: true,
      showSuccessModal: false,
    };
  },
  computed: {
    ...mapGetters({
      detail: 'vendor/orders/getProductDetail',
    }),
    minusDisabled() {
      return this.form.counter <= 1;
    },
    trimName() {
      if (this.detail && 'name' in this.detail) {
        return this.detail.name.toString().trim();
      }
      return '';
    },

    prodPrice() {
      let price = this.form.sizePrice <= 0 ? this.detail.price : this.form.sizePrice;
      return (price - this.detail.dis_price + this.form.typePrice + this.form.addonsPrice) * this.form.counter
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.form = {
            counter: 1,
            price: 0,
            size: null,
            type: null,
            addons: [],
            sizePrice: 0,
            typePrice: 0,
            addonsPrice: 0,
          }
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    cartProduct(newVal) {
      if (newVal != null) {
        this.form.counter = newVal.quantity;


      } else {
        this.form.counter = 1;
      }
    },
    detail(detail) {
      if (detail != null) {
        let cartProd = this.cartProduct;

        if (cartProd) {
          if (cartProd.size) {
            detail.sizes.forEach(item => {
              if (cartProd.size == item.name) {
                this.chooseProductSize(item, true)
              }
            });
          }
          if (cartProd.addons && cartProd.addons.length > 0) {
            detail.addons.forEach(item => {
              if (cartProd.addons.includes(item.name)) {
                this.chooseProductAddons(item, true)
              }
            });
          }

          if (cartProd.type) {
            detail.types.forEach(item => {
              if (cartProd.type == item.name) {
                this.chooseProductType(item, true)
              }
            });
          }
        }
      }

    },
  },
  created() {
    if (this.cartProduct != null) {
      this.form.counter = this.cartProduct.quantity;
    }

  },
  methods: {
    removeFromCart() {
      let time = this.cartProduct.time
      let orderId = this.cartProduct.id;
      let productId = this.cartProduct.product_id;
      this.$store.dispatch('vendor/orders/removeFromCart', {
        order_id: orderId,
        time: time,
        product_id: productId
      });

      this.show = false;
    },

    checkAddonInCart(addon) {
      if (this.cartProduct && this.cartProduct.addons && this.cartProduct.addons.length > 0)
        return this.cartProduct.addons.includes(addon.name);
      return false;
    },
    checkTypeInCart(type) {
      if (this.cartProduct)
        return this.cartProduct.type == type.name;
      return false;
    },
    checkSizeInCart(size) {
      if (this.cartProduct)
        return this.cartProduct.size == size.name;
      return false;
    },
    plusAction() {
      this.form.counter++;
    },
    minusAction() {
      if (this.form.counter > 1) {
        this.form.counter--;
      }

    },
    filterClick(filter) {
      this.form.filter = filter;
    },
    onError() {
      this.showImage = false;
    },


    chooseProductSize(e, fromCart = false) {
      let data = fromCart ? e : JSON.parse(e.target.value);
      this.form.size = data;
      this.form.sizePrice = data.price
      this.error.size = "";
    },
    chooseProductType(e, fromCart = false) {
      let data = fromCart ? e : JSON.parse(e.target.value);
      this.form.type = data
      this.form.typePrice = data.price
    },
    chooseProductAddons(e, fromCart = false) {
      let data = fromCart ? e : JSON.parse(e.target.value);

      if (!this.form.addons.some(item => item.id === data.id)) {
        this.form.addons.push(data);
        this.form.addonsPrice += data.price;
      } else {
        if ('target' in e && !e.target.checked) {
          this.form.addons = this.form.addons.filter(item => item.id !== data.id);
          this.form.addonsPrice -= data.price;
        }
      }
    },

    fillData() {
      const d = new Date();
      let time = d.getTime();
      let orderId = 0
      let productId = this.detail.id;
      if (this.cartProduct) {
        orderId = this.cartProduct.id;
        productId = this.cartProduct.product_id
        time = this.cartProduct.time
      }
      //todo:: update cart
      //if id is 0 update the cart normally
      // if id is not 0 this mean you must add it to cart and update the order

      if (this.form.size == null) {
        alert('must choose size')
        return;
      }
      let addonsIds = this.form.addons.map((item) => item.id);
      let typeId = null
      if (this.form.type && this.form.type.id) {
        typeId = this.form.type.id;
      }
      // $emit('addToCart',cartProduct);
      let apiData = {
        "order_id": orderId,
        "product_id": productId,
        "time": time,
        "id": this.detail.id,
        "size": this.form.size.id,
        "type": typeId,
        "addons": addonsIds,
        "quantity": this.form.counter
      };
      let orderData =
          {
            addons: this.form.addons.map((addon) => addon.name),
            id: orderId,
            product_id: productId,
            time: time,
            image: this.detail.image,
            name: this.detail.name,
            old_price: (this.prodPrice / this.form.counter) + this.detail.dis_price,
            price: (this.prodPrice / this.form.counter) - this.detail.dis_price,
            quantity: this.form.counter,
            size: this.form.size ? this.form.size.name : '',
            type: this.form.type ? this.form.type.name : '',
            total: this.prodPrice,
          }
      if (this.cartProduct) {
        orderId = this.cartProduct.id;      //todo:: update cart
        //if id is 0 update the cart normally
        // if id is not 0 this mean you must add it to cart and update the order
        this.$store.dispatch('vendor/orders/updateCart', {
          api: apiData,
          order: orderData
        })
      } else {

        this.$store.dispatch('vendor/orders/addToCart', {
          api: apiData,
          order: orderData
        })
      }

      this.show = false;
    },
    closeModal() {
      this.show = false;
      this.form = {
        counter: 1,
        price: 0,
        size: null,
        type: null,
        addons: [],
        sizePrice: 0,
        typePrice: 0,
        addonsPrice: 0,
      }
    },
  },

};
</script>
