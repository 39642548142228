<template>
  <b-card
      no-body
      v-if="personInfo"
      class="wameed-card m-0">

    <b-card-header class="align-items-start tab-title">
      <h5 class="text-med-18 text-font-main">
        {{ title }}
      </h5>
    </b-card-header>

    <div class="person-info">
      <div class="data">

        <template v-if="showImageDiv">
          <img v-if="personInfo.image && showImage" :src=personInfo.image :alt=personInfo.name @error="onError"
               class="person-img">
          <div v-else class="person-img">
            <span> <d-person-icon/> </span>
          </div>
        </template>

        <div class="col">
          <router-link class="name" :to="link" v-if="link!=null">{{ personInfo.name }}</router-link>
          <div class="phone">{{ personInfo.phone }}</div>
          <div class="text">{{ personInfo.count }} {{ personInfo.status }}</div>
          <div class="text" v-if="personInfo.address">{{ personInfo.address }}
            <div class="link" @click="openMapModal">({{ $t('users.table.show_addresses') }})</div>
          </div>
        </div>
      </div>
      <div class="d-flex" style="gap:12px;align-items:end" v-if="showBtns">
        <wameed-btn
            :disabled="!enableBtn"
            variant="gray"
            size="sm"
            :title="btn"
            :classes="`text-book-18  rounded-12 w-unset px-4 ${btnColor}`"
            block
            @onClick="onClick"
        />
        <slot/>
      </div>
    </div>
    <map-modal
        :zoom="15"
        :can-update="false"
        :can-search="false"
        :map-type="true"
        :map-markar="geoLocation"
        :visible='openMap'
        @close='openMap = false'
    />

  </b-card>
</template>

<script>
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm'
import MapModal from "@/components/mapModal.vue";

export default {
  components: {MapModal, WameedBtn},
  data() {
    return {
      showImage: true,
      openMap: false,
    }
  },
  props: {
    btn: String,
    title: String,
    link: {},
    btnColor: {
      type: String,
      default: 'secondary-btn'
    },
    showBtns: {
      type: Boolean,
      default: true
    },
    enableBtn: {
      type: Boolean,
      default: true
    },
    info: null
  },
  computed: {
    personInfo() {
      return this.info;
    },
    showImageDiv() {
      return this.personInfo != null && Object.prototype.hasOwnProperty.call(this.personInfo, 'image');
    },
    geoLocation() {
      let _hasLocation = this.personInfo != null ;
      if (_hasLocation) {
        let location = this.personInfo.geo_location;
        if(location == null) return null;
        return {
          lat: location.split(',')[0],
          lng: location.split(',')[1],
        }
      }
      return null;
    }
  },
  methods: {
    onError() {
      this.showImage = false;
    },
    onClick() {
      this.$emit('onClick');
    },
    openMapModal() {
      this.openMap = true;
    }
  }

}
;
</script>
