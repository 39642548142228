<template>
  <validation-observer
      ref="form"
      #default="{ invalid }"
      tag="form"
      class="repeater__form"
      style="overflow: visible"
  >
    <b-modal
        v-model="show"
        content-class="package-modal rounded-8"
        dialog-class="wameed-modal-md"
        no-fade
    >
      <template slot="modal-header-close">
        <close-icon/>
      </template>
      <template slot="modal-title">
        <div class="d-block text-center">
          <h3 class="text-reg-18">
            {{ $t('orders.add_prices') }}
          </h3>
        </div>
      </template>


      <b-row>
        <b-col cols="12" md="6">
          <TextInput
              v-model="form.delivery_price"
              rules="required"
              field-classes="w-100"
              :label="priceText"
              :placeholder="$t('form.text.placeholder')"
              is-append
              type="number"
              :append-text="$t('common.rial')"
          />
        </b-col>
        <b-col cols="12" md="6" v-if="editOrderPrice">
          <TextInput
              v-model="form.order_price"
              rules="required"
              field-classes="w-100"
              :label="$t('orders.medical_price')"
              :placeholder="$t('form.text.placeholder')"
              is-append
              type="number"
              :append-text="$t('common.rial')"
          />
        </b-col>


      </b-row>

      <template slot="modal-footer">
        <wameed-btn
            classes="  text-med-14 text-white mx-0 rounded-8"
            :title="$t('btn.update')"
            :disabled="invalid"
            type="submit"
            variant="main"
            @onClick="submit"
        />
        <wameed-btn
            classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
            :title="$t('btn.cancel')"
            type="button"
            variant="gray"
            @onClick="closeModal()"
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {ValidationObserver} from 'vee-validate';
import {WameedBtn, WameedDropdown, WameedTextInput as TextInput} from 'wameed-ui/dist/wameed-ui.esm';
import {mapGetters} from 'vuex';


export default {
  components: {
    ValidationObserver,
    WameedBtn,
    TextInput,
    WameedDropdown,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    editOrderPrice: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: '',
    },

    priceText: {
      type: String,
      default: '',
    },

  },

  data() {
    return {
      form: {
        delivery_price: '',
        order_price: '',
      },
      showSuccessModal: false,
    };
  },
  computed: {

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    type(newVal) {
      if (newVal != null) {
        this.form.type = newVal;
      } else {
        this.form.type = '';
      }

    },
  },
  created() {
    if (this.type != null) {
      this.form.type = this.type;
    }
  },
  methods: {

    submit() {


      this.$store.dispatch('vendor/orders/updatePrices',
          {
            id: this.$route.params.id,
            ...this.form
          }).then(() => {
        this.$emit('onComplete', true);
        this.closeModal();
        this.$store.commit('loadingFinish', null, {root: true});
      });

    },

    onChange() {
      this.key++;
    },


    closeModal() {
      this.show = false;
    },
  },

};
</script>
