<template>
  <div class="order-detail--card mb-5">
    <div class="order-detail-info">


      <div class="flex">
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.no') }}</div>
          <div class="value">#{{ detail.no }}</div>
        </div>
        <div class="tile-text" >
          <div class="text">{{ $t('table.vehicle_type') }}</div>
          <div class="value">{{ detail.vehicle_type }}</div>
        </div>
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.date') }}</div>
          <div class="value">{{ formatDate(detail.date) }}</div>
        </div>
      </div>

      <div class="flex">
        <div class="tile-text" v-if="detail.vendorId">
          <div class="text">{{ $t('table.price') }}</div>
          <div class="value">{{ formatNumber(detail.total_price) }}</div>
        </div>
        <div class="tile-text" v-else>
          <div class="text">{{ $t('table.delivery_price') }}</div>
          <div class="value">{{ formatNumber(detail.delivery_price) }}</div>
        </div>

        <div class="tile-text">
          <div class="text">{{ $t('orders.table.status') }}</div>
          <div class="value">
            <custom-badge :text="detail.status_name" :color="detail.status_color"/>
          </div>
        </div>
      </div>

      <div class="flex">
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.type') }}</div>
          <div class="value">
            {{ detail.service_type }}
          </div>
        </div>
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.place') }}</div>
          <div class="value">
            <check-value :value="detail.vendor_name"/>
          </div>
        </div>
      </div>


      <div class="flex" v-if="detail.payment">
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.payment_method') }}</div>
          <div class="value">
            {{ detail.payment.method }}
          </div>
        </div>
        <div class="tile-text">
          <div class="text">{{ $t('orders.table.payment_ref') }}</div>
          <div class="value">
            <check-value :value="detail.payment.ref"/>
          </div>
        </div>
      </div>


    </div>

    <div class="h-divider"></div>
    <div class="d-flex justify-content-between align-items-center flex-wrap" style=" gap: 16px;">

      <div class="order-detail-btns " v-if="showBtns">
        <wameed-btn
            v-if="('can_update_price' in detail) && detail.can_update_price"
            variant="main"
            size="sm"
            :title="addPriceBtnText"
            classes="text-book-18  rounded-12   px-4 main-btn"
            block
            @onClick="$emit('onAddPrices')"
        />
        <wameed-btn
            variant="gray"
            size="sm"
            :title="$t('orders.edit')"
            classes="text-book-18  rounded-12   px-4 secondary-btn"
            block
            @onClick="$emit('onEdit')"
        />
        <wameed-btn
            :disabled="!canAccept"
            variant="success"
            size="sm"
            :title="$t('orders.accept')"
            classes="text-book-18  rounded-12   px-4 success-btn"
            block

            @onClick="$emit('onAccept')"
        />
        <wameed-btn
            variant="danger"
            size="sm"
            :title="$t('orders.cancel')"
            classes="text-book-18  rounded-12   px-4"
            block

            @onClick="$emit('onCancel')"
        />
      </div>

      <wameed-btn
          variant="outline-main"
          size="sm"
          prependIcon="btn-arrow-left-icon"
          :title="$t('status.change')"
          style="max-width: 170px;"
          classes="text-book-18  rounded-12 px-4 outline-main-btn rotate-icon"
          block
          @onClick="$emit('onChangeStatus')"
      />
    </div>
  </div>
</template>
<script>
import CheckValue from "@/components/checkValue"
import CustomBadge from "@/components/customBadge"
import {serviceTypes} from "@/enums/serviceType.enum";

export default {
  name: 'infoSection',
  components: {CheckValue, CustomBadge},
  props: {
    detail: {},
    edit: {
      type: Boolean,
      default: false
    },
    addPriceBtnText: {
      type: String,
      default: ""
    },
  },
  computed: {
    showBtns() {
      return this.detail.status_type != null && this.detail.status_type == 0 && this.edit == false;
    },
    canAccept() {
      if(this.detail.service_type_id === serviceTypes.medicine){
        return  this.detail.order_price != null && this.detail.order_price != 0;
      }else if(this.detail.service_type_id === serviceTypes.shipping) {
        return this.detail.delivery_price != null && this.detail.delivery_price != 0;
      }
      return  true;
    }
  }

}
</script>